<template>
    <div class="kfc_loader" v-if="fullScreenLoader">
        <div class="__visibility_block"></div>
        <div class="__loader_wrap">
            <div class="__loader_image"><span></span></div>
            <div class="__loader_content">Уншиж байна ...</div>
        </div>
    </div>
</template>
<script>
import {
  mapGetters
} from 'vuex'
export default {
  props: {
    isLoader: Boolean
  },
  computed: mapGetters({
    fullScreenLoader: 'fullScreenLoader'
  })
}
</script>
