import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from '../views/layout/mainLayout.vue'
import Home from '../views/home/HomePage.vue'
import OrderList from '@/components/order/orderList.vue'
import Login from '@/components/login/loginPage.vue'
import CreateOrder from '@/components/order/createOrder.vue'
import RegisteredUser from '@/components/registeredUser/registeredUser.vue'
import store from '../store'

const routes = [
  {
    path: '/',
    component: MainLayout,
    redirect: '/home',
    // meta: { requiresAuth: true, requiresRole: ['admin', 'operator'] },
    children: [
      {
        path: '/home',
        name: 'home',
        component: Home
        // meta: { requiresAuth: true, requiresRole: ['admin', 'operator'] }
      },
      {
        path: '/orders',
        name: 'Order',
        component: OrderList
        // meta: { requiresAuth: true, requiresRole: ['admin', 'operator'] }
      },
      {
        path: '/createOrder',
        name: 'CreateOrder',
        component: CreateOrder
        // meta: { requiresAuth: true, requiresRole: ['admin', 'operator'] }
      },
      {
        path: '/registeredUser',
        name: 'RegisteredUser',
        component: RegisteredUser
        // meta: { requiresAuth: true, requiresRole: ['admin', 'operator'] }
      }

    ]
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: Login,
    meta: {
      requiresAuth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.state.idToken) {
    next({ name: 'LoginPage' })
  } else if (!to.meta.requiresAuth) {
    next()
  } else {
    if (store.state.user) {
      if (store.state.user.name !== null) {
        next()
      } else {
        next({ name: 'AuthError' })
      }
    } else {
      next()
    }
  }
})

export default router
