<template>
  <el-dialog v-model="ordersDetailDialogStatus" width="90%" :before-close="handleClose"
    :title="targetOrder.company_name+ '/' + targetOrder.branch_name">
    <el-row style="line-height:2.5" class="p-40">
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <div>
           <span>Хэрэглэгчийн утасны дугаар:
              {{ targetOrder.customer_phone }}
          </span><br />
           <span>Захиалгын төлөв:
                <el-tag v-if="targetOrder.order_status == 40" effect="dark">Шинэ</el-tag>
                   <el-tag v-if="targetOrder.order_status == 80" type="warning" effect="dark">Төлбөр төлөгдсөн</el-tag>
                   <el-tag v-if="targetOrder.order_status == 10" type="danger" effect="dark">Цуцлагдсан</el-tag>
                   <el-tag v-if="targetOrder.order_status == 70" type="primary" effect="dark">Хүргэлтэнд гарсан</el-tag>
                   <el-tag v-if="targetOrder.order_status == 100" type="success" effect="dark">Амжилттай</el-tag>
          </span><br />
          <span>Захиалгын дугаар : <span style="font-weight: bold;">{{targetOrder.id}}</span></span><br />
          <!-- <span>Харилцагчийн дугаар: <span v-if="targetOrder.customer_phone == null" style="color:#9c9c9c">Хоосон</span>
            <span v-else style="font-weight: bold;">{{targetOrder.customer_phone}}</span></span><br /> -->
          <span>Төлбөрийн хэлбэр:
            <el-tag v-if="targetOrder.pay_type == 1" effect="dark" type="success">Бэлэн</el-tag>
            <el-tag v-if="targetOrder.pay_type == 2" effect="dark" type="default">Карт</el-tag>
            <el-tag v-if="targetOrder.pay_type == 3" effect="dark" type="warning">Хосолсон</el-tag>
            <el-tag v-if="targetOrder.pay_type == 4" effect="dark" type="danger">Билл</el-tag>
            <el-tag v-if="targetOrder.pay_type == 5" effect="dark" type="danger">Шилжүүлэг</el-tag>
            <el-tag v-if="targetOrder.pay_type == null" effect="dark" type="info">Хоосон</el-tag>
          </span><br />
            <span>Төлбөр төлөгдсөн эсэх:
            <el-tag v-if="targetOrder.is_paid == 1" effect="dark" type="success">Төлөгдсөн</el-tag>
            <el-tag v-if="targetOrder.is_paid == 0" effect="dark" type="info">Төлөгдөөгүй</el-tag>
          </span><br />
          <span>Захиалгын тэмдэглэл: <span style="font-weight: bold;">{{targetOrder.order_description}}</span></span><br />
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <b>Зарсан бүтээгдэхүүн: {{ targetOrder.total_amount }}₮</b>
        <div class="order-item-zone m-t-5" v-for="(detail,index) in targetOrder.detail" :key="index">
          {{ index + 1 }} -
          <span>Бүтээгдэхүүн нэр: <span style="font-weight: bold;">{{detail.product_name}}</span></span><br />
          <span>Нэгж үнэ: <span style="font-weight: bold;">{{detail.item_price}}</span></span><br />
          <span>Тоо ширхэг: <span style="font-weight: bold;">{{detail.item_count}}</span></span><br />
          <span>Нийт үнэ: <span style="font-weight: bold;">{{detail.item_price}} ₮</span></span><br />
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <b>Бүртгэсэн суваг:</b>
        <span v-if="targetOrder.worker_key == 90001">
          Дуудлагын төв
        </span>
          <span v-else-if="targetOrder.worker_key == 90000">
          Веб
        </span>
          <span v-else>
          Тодорхойгүй
        </span>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <b>Ebarimt мэдээлэл:</b>
        <template v-if="targetOrder.ebarimt !== null">
          <span>Ebarimt төлөв: <el-tag v-if="targetOrder.ebarimt != null && targetOrder.ebarimt.ebarimt_status == 20">
              Хүлээгдэж байгаа</el-tag>
            <el-tag v-if="targetOrder.ebarimt != null && targetOrder.ebarimt.ebarimt_status == 10" type="danger">Баримт
              буцаагдсан</el-tag>
            <el-tag v-if="targetOrder.ebarimt != null && targetOrder.ebarimt.ebarimt_status == 100" type="success">
              Баримт илгээгдсэн</el-tag>
            <el-tag v-if="targetOrder.ebarimt == null" type="info">Хоосон</el-tag>
          </span><br />
          <span>НӨАТ хэлбэр:
            <el-tag v-if="targetOrder.vat_type == 1" type="success">Хувь хүн</el-tag>
            <el-tag v-if="targetOrder.vat_type == 3" type="default">Байгууллага</el-tag>
            <el-tag v-if="targetOrder.vat_type == null" type="info">Хоосон</el-tag>
          </span><br />
          <template v-if="targetOrder.vat_type == 3">
            <span>НӨАТ дугаар: <span v-if="targetOrder.vat_regno== null" style="color:#9c9c9c">Хоосон</span>
              <span v-else style="font-weight: bold;">{{targetOrder.vat_regno}}</span></span><br />
          </template>
        </template>
        <template v-else>
          <span>Хоосон</span>
        </template>
      </el-col>
         <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <b>Хаягийн мэдээлэл:</b> <br/>
           <span class="standart_text"> Хаягийн линк: </span>
              <span v-if="targetOrder.address.map_link"> <a :href="targetOrder.address.map_link" target="_blank">{{targetOrder.address.map_link}}</a></span> <br/>
              <el-input class="" type="textarea" style="width: 70%;" :rows="3"
                placeholder="Та google map-аас copy хийж дараагийн хүн олоход туслаарай :)" v-model="map_link">
              </el-input>
                <el-button class="m-l-10 m-b-10" type="success" size="mini" @click="saveAddressMapLink()">Хадгалах</el-button>
        <div class="order-item-zone m-t-10" v-if="targetOrder.address !== null">
          <span>Хот:{{ targetOrder.address.city }}
          </span><br />
             <span>Дүүрэг:{{ targetOrder.address.district }}
          </span><br />
             <span>Хороо:{{ targetOrder.address.horoo }}
          </span><br />
             <span>Байр:{{ targetOrder.address.bairname }}
          </span><br />
           <span>Байр тэмдэглэл:{{ targetOrder.address.bairnote }}
          </span><br />
        </div>
        <div v-else>
          <span>Хоосон</span>
        </div>
      </el-col>
    </el-row>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="danger" v-if="targetOrder.ebarimt !== null && targetOrder.ebarimt.ebarimt_status == 20"
          @click="cancelEbarimt()">
          И-баримт цуцлах
        </el-button>
          <el-button type="primary"
          @click="orderStatusChange('70')">
          Хүргэлтэнд гарсан
        </el-button>
        <el-button type="warning"
        v-if="user.role_id === 1"
          @click="orderIsPaid()">
          Төлбөр төлөгдсөн
        </el-button>
            <el-button type="danger"
          @click="orderStatusChangeCancel('10')">
          Цуцлах
        </el-button>
                   <el-button type="success"
          @click="orderStatusChange('100')">
          Амжилттай
        </el-button>
      </span>
    </template>

  </el-dialog>
</template>

<script>
import { notification } from '@/helpers/custom'
import {
  mapGetters
} from 'vuex'
import services from '@/helpers/services.js'

export default {
  props: {
    targetOrder: Object
  },
  data () {
    return {
      map_link: ''
    }
  },
  computed: mapGetters({
    ordersDetailDialogStatus: 'ordersDetailDialogStatus',
    user: 'userGetter'
  }),
  methods: {
    saveAddressMapLink () {
      this.$root.fullscreenLoading = true
      const payload = {
        address_id: this.targetOrder.address.id,
        map_link: this.map_link
      }
      services.addressLinkSave(payload)
        .then((res) => {
          notification('success_empty')
          this.$root.fullscreenLoading = false
          console.log(res, 'rees')
        }).catch((err) => {
          console.log(err)
        })
    },
    handleClose () {
      this.$store.commit('ordersDetailDialogStatusChange', false)
    },
    orderIsPaid () {
      console.log(this.targetOrder)
      const payload = {
        order_id: this.targetOrder.id
      }
      this.$confirm('Таны ' + this.targetOrder.id + ' дугаартай захиалгын ' + this.targetOrder.total_amount + ' төлбөр орсон уу?', 'Анхаар', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Ороогүй байсан байнөө',
        type: 'warning'
      }).then(() => {
        services.orderIsPaid(payload)
          .then((res) => {
            this.$message({
              type: 'success',
              message: 'Төлбөр төлөгдсөн болголоо.'
            })
          }).catch((err) => {
            console.log(err)
          })
      }).catch(() => {
      })
    },
    orderStatusChange (status) {
      const payload = {
        order_id: this.targetOrder.id,
        order_status: status
      }
      services.orderUpdateStatus(payload)
        .then((res) => {
          console.log(res, 'rees')
        }).catch((err) => {
          console.log(err)
        })
    },
    orderStatusChangeCancel (status) {
      const payload = {
        order_id: this.targetOrder.id,
        order_status: status,
        cancel_reason: ''
      }
      this.$prompt(this.targetOrder.id + ' дугаартай захиалгыг цуцлахад итгэлтэй байна уу?', 'Цуцлах', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Буцяаа'
      }).then(({ value }) => {
        payload.cancel_reason = value
        services.orderUpdateStatusCancel(payload)
          .then((res) => {
            this.$message({
              type: 'success',
              message: 'Захиалга цуцаллаа'
            })
          }).catch((err) => {
            console.log(err)
          })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Цуцлахаа болилоо'
        })
      })
    }
    // cancelEbarimt () {
    //   const self = this
    //   self.$store.commit('fullLoading', true)
    //   const payloadEbarimt = {
    //     bill_id: self.targetOrder.ebarimt.billId
    //   }
    //   axios.post(self.appRoute('ebarimt.return'), payloadEbarimt)
    //     .then(function (response) {
    //       if (response.data.success == true) {
    //         const payload = {
    //           ebarimt_id: self.targetOrder.ebarimt.id,
    //           order_id: self.targetOrder.id
    //         }
    //         axios.post(self.appRoute('finance.orderCancel'), payload).then((res) => {
    //           if (res.data.status == 'success') {
    //             self.$store.commit('fullLoading', false)
    //             ElNotification({
    //               title: 'Амжилттай',
    //               message: 'Цуцлагдлаа.',
    //               type: 'success'
    //             })
    //             location.reload()
    //           } else {
    //             ElNotification({
    //               title: 'Алдаа гарлаа',
    //               message: 'Захиалга цуцлах явцад алдаа гарлаа.',
    //               type: 'error'
    //             })
    //             self.$store.commit('fullLoading', false)
    //           }
    //         }).catch((err) => {
    //           console.log(err)
    //         })
    //       } else if (response.data.success == false && response.data.errorCode == 316) {
    //         ElNotification({
    //           title: 'Алдаа гарлаа',
    //           message: 'Цуцлагдсан баримт байна.',
    //           type: 'error'
    //         })
    //         self.$store.commit('fullLoading', false)
    //       } else {
    //         ElNotification({
    //           title: 'Алдаа гарлаа',
    //           message: response.data.message,
    //           type: 'error'
    //         })
    //         self.$store.commit('fullLoading', false)
    //       }
    //     })
    // }
  }
}

</script>
