<template>
    <div>
        <el-row>
            <el-col :span="18">
                <h3>Бүртгүүлсэн хэрэглэгчийн жагсаалт</h3>
            </el-col>
        </el-row>
        <el-row class="m-t-30">
          <el-col :span="18">
            <h5 style="margin-bottom: 10px">Бүртгүүлсэн хэрэглэгчид байгууллагаар</h5>
          </el-col>
          <el-col>
              <el-tag>
                Батлах хамгаалах яам -  {{ orgaanizationCounter("Батлах хамгаалах яам") }}
              </el-tag>
              <el-tag class="ml-10">
                Зэвсэгт хүчний жанжин штаб -  {{ orgaanizationCounter("Зэвсэгт хүчний жанжин штаб") }}
              </el-tag>
              <el-tag class="ml-10">
                Хил хамгаалах ерөнхий газар -  {{ orgaanizationCounter("Хил хамгаалах ерөнхий газар") }}
              </el-tag>
              <el-tag class="ml-10">
                Онцгой байдлын ерөнхий газар -  {{ orgaanizationCounter("Онцгой байдлын ерөнхий газар") }}
              </el-tag>
              <el-tag class="ml-10">
                Цагдаагийн ерөнхий газар -  {{ orgaanizationCounter("Цагдаагийн ерөнхий газар") }}
              </el-tag>
              <el-tag class="ml-10">
                Тагнуулын ерөнхий газар -  {{ orgaanizationCounter("Тагнуулын ерөнхий газар") }}
              </el-tag>
              <el-tag class="ml-10">
                Төрийн тусгай хамгаалалтын газар -  {{ orgaanizationCounter("Төрийн тусгай хамгаалалтын газар") }}
              </el-tag>
              <el-tag class="ml-10">
                Дотоодын цэрэг -  {{ orgaanizationCounter("Дотоодын цэрэг") }}
              </el-tag>
              <el-tag class="ml-10">
                Шүүх шинжилгээний ерөнхий газар -  {{ orgaanizationCounter("Шүүх шинжилгээний ерөнхий газар") }}
              </el-tag>
              <el-tag class="ml-10">
                Шүүхийн шийдвэр гүйцэтгэх ерөнхий газар -  {{ orgaanizationCounter("Шүүхийн шийдвэр гүйцэтгэх ерөнхий газар") }}
              </el-tag>
          </el-col>
          <el-col>
          </el-col>
        </el-row>
        <el-row  class="m-t-30">
            <el-col>
                <el-table :data="usersList">
                    <el-table-column type="index" label="№"></el-table-column>
                    <el-table-column prop="ovog" label="Овог"></el-table-column>
                    <el-table-column prop="name" label="Нэр"></el-table-column>
                    <el-table-column width="250px" prop="organization_name" label="Ажилладаг байгууллага"></el-table-column>
                    <el-table-column width="250px" prop="tsol" label="Цол"></el-table-column>
                    <el-table-column prop="weight" label="Орлцох төрөл"></el-table-column>
                    <el-table-column prop="age" label="Нас"></el-table-column>
                    <el-table-column prop="phone" label="Утас"></el-table-column>
                </el-table>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import services from '@/helpers/services.js'

export default {
  name: 'registeredUser',
  data () {
    return {
      usersList: []
    }
  },
  created () {
    this.championUser()
  },
  methods: {
    championUser () {
      const payload = {
        type: 'giir1'
      }
      services.getChampionUsers(payload)
        .then((response) => {
          if (response.status === 'success') {
            this.usersList = response.result
          }
        })
    },
    orgaanizationCounter (status) {
      let count = 0
      this.usersList.forEach(element => {
        if (element.organization_name === status) {
          count = count + 1
        }
      })
      return count
    }
  }
}

</script>

<style>
.ml-10{
  margin-left: 10px;
  margin-bottom: 10px;
}
</style>
