<template>
  <div class="user_list p-20">
    <el-row class="user_head_layout">
      <el-col :span="18">
        <h3>Захиалгын жагсаалт</h3>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg m-t-20" justify="center" :gutter="20">
      <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
        <el-tag shadow="always" class="p-30 w-100" type="success">
          {{ statusCounter(100) }} - Хүргэгдсэн
        </el-tag>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
        <el-tag shadow="always" class="p-30 w-100" type="warning">
          {{ statusCounter(60) }} - Жолоочид хувиарласан
        </el-tag>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
        <el-tag shadow="always" class="p-30 w-100" type="info">
          {{ statusCounter(40) }} - Шинэ захиалга
        </el-tag>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
        <el-tag shadow="always" class="p-30 w-100" type="danger">
          {{ statusCounter(10) }} - Цуцлагдсан
        </el-tag>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
        <el-tag shadow="always" effect="dark" class="p-30 w-100" type="success">
          {{ checkPaymentCount(1) }} - Төлбөр төлөгдсөн
        </el-tag>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
        <el-tag shadow="always" effect="dark" class="p-30 w-100" type="info">
          {{ checkPaymentCount(0) }} - Төлөгдөөгүй
        </el-tag>
      </el-col>
    </el-row>
    <h5 class="m-t-20">Жолооч хүргэсэн байдал</h5>
     <el-row type="flex" class="row-bg m-t-20" justify="center" :gutter="20">
      <template v-for="employee, index in employeeList" :key="index">
    <el-col :xs="24" :sm="12" :md="8" :lg="2" :xl="2"  v-if="countDriver(employee.id) > 0">
          <el-tag shadow="always" effect="dark" type="info" class="p-20 w-100" >
            <span>
            {{ employee.name }}-{{ countDriver(employee.id) }}
            </span>
        </el-tag>
      </el-col>
      </template>
    </el-row>
    <div v-if="userGetter.company_id === 9">
      <h5 class="m-t-20">Бүтээгдэхүүн тоо хэмжээ</h5>
      <el-row class="m-t-10">
        <el-col :span="22" :offset="1"><el-row type="flex" class="row-bg m-t-10" justify="space-around">
          <el-col :span="4">
            <div class="schedule_detail_count p-10" style="background-color: #e69d45; color: white;">
            S -
              <span>{{ this.s}}</span>
            </div>
          </el-col>
          <el-col :span="4">
              <div class="schedule_detail_count p-10" style="background-color: #d45769; color: white;">
              M -
              <span>{{ this.m}}</span>
              </div>
          </el-col>
          <el-col :span="4">
              <div class="schedule_detail_count p-10" style="background-color: #308695; color: white;">
              L -
              <span>{{ this.l}}</span>
              </div>
          </el-col>
          <el-col :span="4">
              <div class="schedule_detail_count p-10" style="background-color: #3b5284; color: white;">
              XL -
              <span>{{ this.xl}}</span>
              </div>
          </el-col>
          <el-col :span="4">
              <div class="schedule_detail_count p-10">
              Саль -
              <span>{{ this.salipetka}}</span>
              </div>
          </el-col>
        </el-row>
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="23" class="m-t-30" style="line-height:2">
      <el-col :xs="23" :sm="23" :md="12" :lg="5" :xl="4" class="branch_manager_date_picker">
        <el-date-picker class="branch_manager_order_date_picker" clearable v-model="startDate" type="datetime"
          size="small" placeholder="Эхлэх огноо сонгох">
        </el-date-picker>
      </el-col>
      <el-col :xs="23" :sm="23" :md="12" :lg="5" :xl="4" class="branch_manager_date_picker">
        <el-date-picker class="branch_manager_order_date_picker" clearable v-model="endDate" size="small"
          type="datetime" placeholder="Дуусах огноо сонгох">
        </el-date-picker>
      </el-col>
      <el-col :xs="12" :sm="12" :md="12" :lg="1" :xl="1" class="branch_manager_date_picker">
        <el-button type="info" @click="getView()" class="branch_manager_order_date_picker" size="small" clearable>
          Хайх
        </el-button>
      </el-col>
      <el-col :xs="12" :sm="12" :md="12" :lg="5" :xl="4" class="branch_manager_date_picker">
        <el-button size="small" type="success" @click="goToAddUser()">+ Захиалга үүсгэх</el-button>
      </el-col>
      <!-- <el-col  :xs="23" :sm="23" :md="12" :lg="4" :xl="4" class="branch_manager_date_picker">
          <el-button @click="onexport()" class="branch_manager_order_date_picker" type="success">
           Татаж авах (.xslx)
          </el-button>
        </el-col> -->
    </el-row>
    <el-row v-if="tabledata.length == 0 && tabledata.length == 0">
      <el-col>
        <span class="empty_img m-t-180"></span>
      </el-col>
      <h3 class="empty_text">Хоосон байна</h3>
    </el-row>
    <el-row class="m-t-20">
      <el-col :span="24" class="user_body">
        <el-table :border="true" size="small" :data="tabledata" style="width: 100%" v-loading="loading">
          <el-table-column fixed type="index" width="30">
          </el-table-column>
          <el-table-column prop="created_at" label="Огноо" sortable fixed width="150">
            <template #default="scope">
              {{ dateFormat(scope.row.created_at)}}
            </template>
          </el-table-column>
          <el-table-column prop="id" width="70" label="Захиалгын дугаар" sortable>
          </el-table-column>
          <el-table-column prop="order_status" label="Төлбөр" width="105"
            :filters="[{ text: 'Төлөгдсөн', value: 1 }, { text: 'Төлөгдөөгүй', value: 0 }]"
            :filter-method="filterIsPaid">
            <template #default="scope">
              <el-tag type="success" effect="dark" v-if="scope.row.is_paid === 1">Төлөгдсөн
              </el-tag>
              <el-tag type="info" effect="dark" v-else-if="scope.row.is_paid === 0">Төлөгдөөгүй
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="worker_key" label="Суваг">
            <template #default="scope">
              <el-tag type="success" v-if="scope.row.order_channel === 'cc'">Дуудлага
              </el-tag>
              <el-tag type="warning" v-else-if="scope.row.order_channel === 'web'">Веб
              </el-tag>
              <el-tag type="primary" v-else-if="scope.row.order_channel === 'chat'">Чат
              </el-tag>
              <span style="color:#eee" v-else>Тодорхойгүй
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="total_amount" label="Үнийн дүн" width="100">
            <template #default="scope">
              {{ scope.row.total_amount }}₮
            </template>
          </el-table-column>
          <el-table-column prop="order_status" label="Төлөв" width="150" :filters="[{ text: 'Цуцлагдсан', value: 10 }, { text: 'Жолоочид хуваарилсан', value: 60 }, { text: 'Амжилттай', value: 100 },{ text: 'Шинэ', value: 40 }]"
            :filter-method="filterOrderStatus">
            <template #default="scope">
              <el-tag type="info" v-if="scope.row.order_status === 40">Шинэ
              </el-tag>
              <el-tag type="danger" v-if="scope.row.order_status === 10">Цуцлагдсан
              </el-tag>
              <el-tag type="warning" v-else-if="scope.row.order_status === 60">Жолоочид хуваарилсан
              </el-tag>
              <el-tag type="primary" v-else-if="scope.row.order_status === 70">Хүргэлтэнд гарсан
              </el-tag>
              <el-tag type="success" v-else-if="scope.row.order_status === 100">Амжилттай
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="customer_phone" label="Захиалагчийн утас" width="130">
            <template #default="scope">
                {{ scope.row.customer_phone }} <el-tag type="success"> {{ scope.row.order_count_count }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="worker_key" label="Жолооч" :filters="[{ text: 'Өскө', value: 'Өскө' }, { text: 'Молор-Эрдэнэ', value: 'Молор-Эрдэнэ' }, { text: 'Далантай', value: 'Далантай' },{ text: 'Лхам', value: 'Лхам' }]"
            :filter-method="filterWorkerNameStatus">
            <template #default="scope">
              <el-tag type="success" v-if="scope.row.drive"> {{ scope.row.drive.name }}
              </el-tag>
              <span v-else style="color:#eee">Жолоочгүй
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="worker_key" label="Хаяг" width="800px">
            <template #default="scope">
              <span v-if="scope.row.address" style="color:gray">
                <span>{{ scope.row.address.city }}-{{ scope.row.address.district }} - {{ scope.row.address.horoo }} -
                  {{ scope.row.address.bairname }}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" width="300px" label="Үйлдэл">
            <template #default="scope">
              <!-- <el-button size="small" type="primary" @click="handleEdit(scope.row)">
                Засах
              </el-button> -->
              <!-- <el-button v-loading="loading" type="info" size="mini" @click="viewDetail(scope.row)  ">Дэлгэрэнгүй
              </el-button> -->
              <el-button size="small" type="text" @click="orderDetailShow(scope.row)">
                Дэлгэрэнгүй
              </el-button>
              <el-button size="small" type="text" @click="orderConnectDrive(scope.row)">
                Ж/хуваарилах
              </el-button>
              <el-button size="small" v-if="scope.row.is_paid != 1" type="text" @click="sendSmsDialog(scope.row)">
                МСЖ
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <OrdersDetailDialog :targetOrder="targetOrder" />
    <el-dialog title="Жолоочид хуваарилах" v-model="driveDialog" center width="750px"
      :before-close="handleCloseDriveDialog">
      <div class="p-30">
        <span v-loading="this.driveDialogLoading">
          <el-table :data="this.employeeList" style="width: 100%">
            <el-table-column prop="name" label="Жолоочийн нэр" />
            <el-table-column prop="phone" label="Утас" />
            <el-table-column label="Үйлдэл">
              <template #default="scope">
                <el-button size="small" type="success" @click="connectOrderDrive(scope.row)">Хуваарилах</el-button>
              </template>
            </el-table-column>
          </el-table>
        </span>
      </div>
    </el-dialog>
    <el-dialog title="МСЖ илгээх" v-model="this.smsDialog" width="30%" :before-close="handleCloseSmsDialog">
      <div class="p-20">
        <el-form :model="sendSmsForm" label-width="120px" label-position="top" class="demo-ruleForm">
          <el-form-item label="Утасны дугаар">
            <el-input-number class="w-100" disabled v-model="sendSmsForm.to" placeholder="Утасны дугаар"
              :controls="false"></el-input-number>
          </el-form-item>
          <el-form-item v-if="!templateIsCheck && userGetter.company_id === 9" label="Мсж текст">
            <div class="sms_text_zone">
              {{ userGetter.company_id }}
              <span class="sendSmsTestTitleStyle p-t-40">Сайн байна уу? Таны {{this.selectOrder.created_at}}-ны өдөр
                захиалсан живхний төлбөр төлөгдөөгүй байна. Данс: 5305979107 Нэр: Б. Мөнхнасан Дүн:
                {{ this.selectOrder.total_amount }} Гүйлгээний утга: {{this.selectOrder.id}}</span>
            </div>
          </el-form-item>
          <el-form-item v-if="!templateIsCheck && userGetter.company_id === 10" label="Мсж текст">
            <div class="sms_text_zone">
              {{ userGetter.company_id }}
              <span class="sendSmsTestTitleStyle p-t-40">Сайн байна уу? Таны {{this.selectOrder.created_at}}-ны өдөр
                захиалсан живхний төлбөр төлөгдөөгүй байна. Данс: 5305979107 Нэр: Б. Мөнхнасан Дүн:
                {{ this.selectOrder.total_amount }} Гүйлгээний утга: {{this.selectOrder.id}}</span>
            </div>
          </el-form-item>
          <el-form-item v-if="!templateIsCheck && userGetter.company_id === 11" label="Мсж текст">
            <div class="sms_text_zone">
              {{ userGetter.company_id }}
              <span class="sendSmsTestTitleStyle p-t-40">Сайн байна уу? Таны {{this.selectOrder.created_at}}-ны өдөр
                захиалсан живхний төлбөр төлөгдөөгүй байна. ХХБ банк,  Данс: 404320221 Нэр: Саруулбат, Дүн:
                {{ this.selectOrder.total_amount }} Гүйлгээний утга: {{this.selectOrder.id}}</span>
            </div>
          </el-form-item>
        </el-form>
        <span class="dialog-footer">
          <el-button type="success" @click="sendSms()">Илгээх</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import services from '@/helpers/services.js'
import OrdersDetailDialog from './orderDetailDialog.vue'
import {
  notification
} from '@/helpers/custom.js'
import moment from 'moment'
import {
  mapGetters
} from 'vuex'
moment().format()

export default {
  components: {
    OrdersDetailDialog
  },
  computed: mapGetters({
    companyInfoGetter: 'companyInfoGetter',
    userGetter: 'userGetter'
  }),
  data () {
    return {
      tabledata: [],
      employeeList: [],
      selectedUserInfo: {},
      viewDetailViewDialog: false,
      loading: true,
      roleList: [],
      startDate: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }).add(8, 'hour'),
      endDate: moment().add(1, 'day'),
      targetOrder: {},
      driveDialog: false,
      driveDialogLoading: false,
      smsDialog: false,
      sendSmsForm: {
        to: null,
        text: ''
      },
      s: 0,
      m: 0,
      l: 0,
      xl: 0,
      salipetka: 0
    }
  },
  created () {
    // this.getData()
    this.getView()
    this.getEmmloyeeList()
  },
  methods: {
    countSizer () {
      this.tabledata.forEach(element => {
        element.detail.forEach(e => {
          if (e.product_name === 'S size drysheep 48pcs' || e.product_name === 'Бэлэг S size drysheep 48pcs') {
            this.s = this.s + e.item_count
          } else if (e.product_name === 'M size drysheep 44pcs' || e.product_name === 'Бэлэг M size drysheep 44pcs') {
            this.m = this.m + e.item_count
          } else if (e.product_name === 'L size drysheep 36pcs' || e.product_name === 'Бэлэг L size drysheep 36pcs') {
            this.l = this.l + e.item_count
          } else if (e.product_name === 'XL size drysheep 32 pcs' || e.product_name === 'Бэлэг XL size drysheep 32 pcs') {
            this.xl = this.xl + e.item_count
          } else if (e.product_name === 'Нойтон салфетка' || e.product_name === 'Бэлэг Нойтон салфетка' || e.product_name === 'Бэлэг нойтон салфетка') {
            this.salipetka = this.salipetka + e.item_count
          }
        })
      })
    },
    countDriver (param) {
      let count = 0
      this.tabledata.forEach(element => {
        if (element.worker_key === param) {
          count = count + 1
        }
      })
      return count
    },
    filterIsPaid (value, row) {
      return row.is_paid === value
    },
    filterWorkerNameStatus (value, row) {
      return row.drive.name === value
    },
    filterOrderStatus (value, row) {
      return row.order_status === value
    },
    checkPaymentCount (param) {
      let count = 0
      this.tabledata.forEach(element => {
        if (element.is_paid === param) {
          count = count + 1
        }
      })
      return count
    },
    statusCounter (status) {
      let count = 0
      this.tabledata.forEach(element => {
        if (element.order_status === status) {
          count = count + 1
        }
      })
      return count
    },
    yyyymmdd () {
      const today = new Date()
      const yyyy = today.getFullYear()
      const mm = today.getMonth() + 1 // getMonth() is zero-based
      const dd = today.getDate()
      return String(10000 * yyyy + 100 * mm + dd) // Leading zeros for mm and dd
    },
    connectOrderDrive (worker) {
      const payload = {
        order_id: this.targetOrder.id,
        worker_key: worker.id
      }
      services.connectOrderDrive(payload)
        .then((res) => {
          notification('success_empty')
          this.getView()
          console.log(res, 'rees')
        }).catch((err) => {
          console.log(err)
        })
    },
    handleCloseSmsDialog () {
      this.smsDialog = false
    },
    sendSmsDialog (data) {
      this.smsDialog = true
      this.selectOrder = data
      if (data) {
        this.sendSmsForm.to = data.customer_phone
        this.sendSmsForm.text = 'Сайн байна уу? Таны ' + data.created_at +
            '-ны өдөр захиалсан живхний төлбөр төлөгдөөгүй байна. Данс: 5305979107 Нэр: Б. Мөнхнасан Дүн: ' + data
          .total_amount + ' Гүйлгээний утга: ' + data.id
      } else {
        this.sendSmsForm.to = null
        this.sendSmsForm.text = ''
      }
    },
    sendSms () {
      console.log(this.sendSmsForm)
      if (this.sendSmsForm.to === null || this.sendSmsForm.text === '') {
        this.$notify.error({
          title: 'Error',
          message: 'Алдаа утасны дугаар хоосон байна.'
        })
      } else {
        this.$root.fullscreenLoading = true
        services.sendSms(this.sendSmsForm)
          .then((response) => {
            if (response[0].Result === 'SUCCESS') {
              notification('success_empty')
              this.$root.fullscreenLoading = false
              this.handleCloseSmsDialog()
            } else {
              this.$notify.error({
                title: 'Уучлаарай',
                message: 'Алдаа гарлаа инженертэй холбогдоно уу'
              })
              this.$root.fullscreenLoading = false
            }
          })
      }
    },
    handleCloseDriveDialog () {
      this.driveDialog = false
    },
    orderDetailShow (data) {
      this.targetOrder = data
      this.$store.commit('ordersDetailDialogStatusChange', true)
    },
    orderConnectDrive (selectOrder) {
      this.driveDialog = true
      this.targetOrder = selectOrder
    },
    getEmmloyeeList () {
      const payload = {
        company_id: this.companyInfoGetter.id
      }
      services.getEmployeeList(payload)
        .then((response) => {
          if (response.status === 'success') {
            this.employeeList = response.result
          }
        })
    },
    dateFormat (date) {
      return date
    },
    goToAddUser () {
      this.$router.push({
        path: '/createOrder'
      })
    },
    getView () {
      const payload = {
        start_date: this.startDate,
        end_date: this.endDate,
        company_id: this.companyInfoGetter.id
      }
      services.orderList(payload)
        .then((response) => {
          if (response.status === 'success') {
            this.tabledata = response.result
            this.countSizer()
            this.loading = false
          }
        })
    },
    handleEdit (row) {
      this.$router.push({
        name: 'Useradd',
        params: {
          id: row.id
        }
      })
    },
    handleDelete (row) {
      this.$confirm(row.name + ' хэрэглэгчийн мэдээллийг устгахдаа итгэлтэй байна уу?', 'Анхаар', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Буцах',
        type: 'warning'
      }).then(() => {
        this.$root.fullscreenLoading = true
        const del = {
          id: row.id
        }
        services.deleteUser(del)
          .then((response) => {
            if (response.status === 'success') {
              notification('delete_user_notification')
              // this.getData()
              location.reload()
              this.$root.fullscreenLoading = false
            } else if (response.status === 'unSuccess') {
              notification('delete_error_user')
              this.$root.fullscreenLoading = false
            } else {
              // console.log('error')
            }
          })
      })
    }
  }
}

</script>

<style>

</style>
