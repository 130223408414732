import config from '@/config/index'
import { getAccessToken } from '@/helpers/auth.js'
import { ElNotification } from 'element-plus'
import { notificationText } from './json/notification.js'

export const mainRequests = {
  post,
  get,
  deleteFunction,
  getAuth,
  postAuth
}

function handleResponse (response) {
  return response
    .text()
    .then(text => {
      const data = text && JSON.parse(text)
      if (!response.ok) {
        if (response.status === 401) {
          //   logout()npm run serve
          // location.reload()
        }

        const error = (data && data.message) || response.statusText
        return Promise.reject(error)
      }

      return data
    })
    .catch(err => {
      return Promise.reject(err)
    })
}
async function post (url, payload) {
  const promise = new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }
    fetch(`${config.BASE_SERVER_PATH}` + url, requestOptions)
      .then(handleResponse)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  })
  const result = await promise
  return result
}
async function get (url) {
  const promise = new Promise((resolve, reject) => {
    // const requestOptions = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' }
    // }
    console.log(config)
    fetch(`${config.BASE_SERVER_PATH}` + url)
      .then(handleResponse)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        notification('error_empty')
        reject(error)
      })
  })
  const result = await promise
  return result
}

async function postAuth (url, payload = {}) {
  const promise = new Promise((resolve, reject) => {
    const token = getAccessToken()
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    }
    fetch(`${config.BASE_SERVER_PATH}` + url, requestOptions)
      .then(handleResponse)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  })
  const result = await promise
  return result
}
async function getAuth (url) {
  const promise = new Promise((resolve, reject) => {
    const token = getAccessToken()
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    fetch(`${config.BASE_SERVER_PATH}` + url, requestOptions)
      .then(handleResponse)
      .then(data => {
        if (data.message === 'empty_role') {
          notification('auth_error')
          window.location.href = '/authError'
        } else {
          resolve(data)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
  const result = await promise
  return result
}
async function deleteFunction (url, payload) {
  const promise = new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }
    fetch(`${config.BASE_SERVER_PATH}` + url, requestOptions)
      .then(handleResponse)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  })
  const result = await promise
  return result
}
export async function notification (type, title, message, position) {
  if (type === 'info' || type === 'success' || type === 'warning' || type === 'error') {
    ElNotification({
      message: message,
      duration: 2500,
      type: type,
      showClose: true,
      title: title,
      position: position
    })
  } else {
    const found = notificationText.find(element => element.key === type)
    ElNotification({
      message: found.text_mon,
      duration: 2500,
      type: found.type,
      showClose: true,
      title: found.title_mon,
      position: found.position
    })
  }
}
export function checkPermissions (permission) {
  const role = JSON.parse(window.localStorage.getItem('role'))
  const found = role.permissions.find(element => element.name_eng === permission)
  if (found) {
    return true
  } else {
    return false
  }
}
export function returnValidationErrorMessage (response) {
  Object.keys(response.data).forEach((element, index) => {
    console.log(element, 'Алдаа')
    ElNotification({
      title: 'Алдаа',
      type: 'error',
      message: response.data[element],
      offset: (index * 100)
    })
  })
}
export function dateFormatFunction (date) {
  return date
}
