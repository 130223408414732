import { createStore } from 'vuex'
import services from '@/helpers/services.js'

const idToken = window.localStorage.getItem('idToken')
const userId = window.localStorage.getItem('userId')
const role = window.localStorage.getItem('role')
const user = window.localStorage.getItem('user')
const activeTab = window.localStorage.getItem('activeTab')
const lastOrder = window.localStorage.getItem('lastOrder')
const company = window.localStorage.getItem('company')

export default createStore({
  state () {
    return {
      idToken: idToken ? JSON.parse(idToken) : null,
      userId: userId ? JSON.parse(userId) : null,
      role: role ? JSON.parse(role) : [],
      userInfo: user ? JSON.parse(user) : null,
      activeTab: activeTab,
      ordersDetailDialog: false,
      lastOrder: lastOrder ? JSON.parse(lastOrder) : {},
      fullScreenLoader: false,
      drawerGetterState: false,
      companyInfo: company ? JSON.parse(company) : null
    }
  },
  getters: {
    idToken: state => state.idToken,
    activeTab: state => state.activeTab,
    ordersDetailDialogStatus: state => state.ordersDetailDialog,
    lastOrderGetter: state => state.lastOrder,
    userGetter: state => state.userInfo,
    fullScreenLoader: state => state.fullScreenLoader,
    drawerGetterVar: state => state.drawerGetterState,
    companyInfoGetter: state => state.companyInfo
  },
  mutations: {
    sidebarDrawerStatusChange (state, status) {
      state.drawerGetterState = status
    },
    fullLoading (state, loading) {
      state.fullScreenLoader = loading
    },
    clearBasket (state, category) {
      state.items = []
      window.localStorage.setItem('basket_items', JSON.stringify(state.items))
    },
    addLastOrder (state, lastOrderValue) {
      state.lastOrder = lastOrderValue
      window.localStorage.setItem('lastOrder', JSON.stringify(state.lastOrder))
    },
    ordersDetailDialogStatusChange (state, status) {
      state.ordersDetailDialog = status
    },
    changeTab (state, tabValue) {
      state.activeTab = tabValue
      window.localStorage.setItem('activeTab', parseInt(state.activeTab))
    },
    authUser (state, userData) {
      state.idToken = userData.success.token
      state.user = userData
      window.localStorage.setItem('idToken', JSON.stringify(state.idToken))
      window.localStorage.setItem('user', JSON.stringify(state.user))
    },
    addRole (state, roleData) {
      state.role = roleData.role
      window.localStorage.setItem('role', JSON.stringify(state.role))
    },
    addUserInfo (state, userData) {
      state.userInfo = userData
      window.localStorage.setItem('user', JSON.stringify(state.userInfo))
    },
    addCompanyInfo (state, Data) {
      state.dataInfo = Data
      window.localStorage.setItem('company', JSON.stringify(state.dataInfo))
    }
  },
  actions: {
    clearBasket ({ commit }, value) {
      commit('clearBasket', value)
    },
    addLastOrder ({ commit }, value) {
      commit('addLastOrder', value)
    },
    changeActiveTab ({ commit }, response) {
      commit('changeTab', response)
    },
    async authUserLoginPage ({ commit }, response) {
      commit('authUser', response)
    },
    async addRoleLoginPage ({ commit }, response) {
      commit('addRole', response)
    },
    async userAdd ({ commit }, response) {
      commit('addUserInfo', response)
    },
    async companyAdd ({ commit }, response) {
      commit('addCompanyInfo', response)
    },
    async signin ({ commit }, authData) {
      return new Promise((resolve, reject) => {
        services.login(authData)
          .then((response) => {
            resolve(response)
          },
          error => {
            reject(error)
          })
      })
    }
  },
  modules: {}
})
