<template>
  <div class="p-20">
      <el-drawer
    v-model="drawerGetter"
    title="Меню"
    direction="ltr"
    size="70%"
    :before-close="handleCloseMenu"
  >
        <el-menu class="sidebar-menu" :default-active="defaultActiveMenu" background-color="white" text-color="gray"
          style="border: none; font-weight: bold; min-height: 800px;" active-text-color="#0AAADC">
          <router-link to="/">
            <el-menu-item>
              Хянах самбар
            </el-menu-item>
          </router-link>
          <router-link to="/orders">
            <el-menu-item>
              Захиалгийн жагсаалт
            </el-menu-item>
          </router-link>
          <router-link to="/registeredUser">
            <el-menu-item  v-if="user.company_id === 11">
              <!-- <el-menu-item> -->
              Бүртгэлтэй хэрэглэгчид
            </el-menu-item>
          </router-link>
          <!-- <el-menu-item-group v-for="(parent, parentIndex) in menuList" :key="parent.caption + '_' + parentIndex"
            :title="parent.caption" class="m-t-20">
            <div v-for="(mainmenu, mainmenuIndex) in parent.list" :key="mainmenuIndex + '_' + mainmenuIndex">
                <router-link :to="mainmenu.to">
                  <el-menu-item class="sidebar-dropdown" :index="parentIndex+'-'+mainmenuIndex">
                    <i :class="mainmenu.icon"></i>
                    <span>{{ mainmenu.title }}</span>
                  </el-menu-item>
                </router-link>
              </div>
          </el-menu-item-group> -->
        </el-menu>

      <!-- sidebar-content  -->
      <div style="text-align: center;">
        <el-button class="logout_text" type="text" @click="logout()">
          Гарах
        </el-button>
      </div>
  </el-drawer>
  <el-container>
    <el-header class="my-header" >
            <el-button @click="openMenuDrawer()" >Цэс</el-button>
      <span class="m-r-10 flr">{{ user.name }}</span>
    </el-header>
    <main class="page-content">
      <router-view />
    </main>
  </el-container>
    <loader-item></loader-item>
  </div>
</template>
<script>
import LoaderItem from '@/components/loader/loader-item.vue'
import { mapGetters } from 'vuex'

export default {

  components: {
    LoaderItem
  },
  computed: {
    ...mapGetters({
      user: 'userGetter',
      drawerGetter: 'drawerGetterVar'
    })
  },
  data () {
    return {
      defaultActiveMenu: '1-1-0-0',
      containerCollapsed: 'toggled',
      windowWidth: window.innerWidth,
      menuList: [{
        caption: 'Үндсэн мэню',
        list: [{
          title: 'Хянах самбар',
          icon: 'el-icon-data-analysis',
          to: '/home'
        },
        {
          title: 'Захиалгийн жагсаалт',
          icon: 'el-icon-data-analysis',
          to: '/orders'
        },
        {
          title: 'Бүртгэлтэй хэрэглэгчид',
          icon: 'el-icon-data-analysis',
          to: '/registeredUser'
        }
        ]
      }
      ]
    }
  },
  created () {
  },
  methods: {
    handleCloseMenu () {
      this.$store.commit('sidebarDrawerStatusChange', false)
    },
    openMenuDrawer () {
      this.$store.commit('sidebarDrawerStatusChange', true)
    },
    logout () {
      localStorage.clear()
      this.$router.push({
        path: '/login'
      })
    }
  }
}

</script>
<style scoped>
  .el-header {
    margin-top: -19px;
    background-color: #ffffff;
    color: #333;
    line-height: 60px;
    padding: 0px;
    min-height: 68px;
    padding-right: 40px;
  }

  .el-aside {
    color: #333;
  }

  .item {
    margin-top: 12px;
    margin-right: 20px;
  }

</style>
