<template>
  <div>
    <b>Захиалга сараар</b>
    <el-card shadow="hover" class="box-card carousel-card m-t-20">
<Bar
v-if="status==true"
    id="my-chart-id"
    :options="chartOptions"
    :data="chartData"
  />
    </el-card>
  </div>

</template>

<script>
import {
  Bar
} from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import services from '@/helpers/services.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: {
    Bar
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 200
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      status: false,
      months: [],
      values: [],
      chartData: {
        labels: [],
        datasets: [{
          label: 'Сараар борлуулалтын дүн',
          backgroundColor: '#138086',
          data: []
        }]
      },

      chartOptions: {
        responsive: true
      }
    }
  },
  created () {
    this.getSalesData()
  },
  methods: {
    statusCounter (status) {
      let count = 0
      this.tabledata.forEach(element => {
        if (element.order_status === status) {
          count = count + 1
        }
      })
      return count
    },
    getSalesData () {
      // const months = []
      // const values = []
      const self = this
      services.orderStatistic()
        .then(function (response) {
          console.log(response, 'rees')
          response.forEach(element => {
            self.chartData.labels.push(element.month.toString())
            if (element.total_amount != null) {
              self.chartData.datasets[0].data.push(element.total_amount.toString())
            } else {
              self.chartData.datasets[0].data.push(0)
            }
          })
          self.status = true
        })
      // self.chartData.labels = self.months
      // console.log(self.chartData, '№№')
      // self.chartData.datasets[0].data = self.values
    }
  }
}

</script>
