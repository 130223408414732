<template>
  <div class="p-20">
    <h4>Өмнөх захиалгын түүх хэсэг</h4>
    <!-- <h3>Салбар сонгох</h3>
  <el-select v-model="branch_id" placeholder="Бүтээгдэхүүн худалдах салбар" class="w-100 m-t-20">
    <el-option v-for="(branch, index) in branches" :key="index" :label="branch.name" :value="branch.id" />
  </el-select> -->
    <el-row type="flex" class="row-bg m-t-20" :gutter="20">
      <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
        <el-tag shadow="always" class="p-20 w-100"  effect="dark" type="success">
          {{ this.orderForm.order.customer_phone }} - дугаартай хэрэглэгч
        </el-tag>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
        <el-tag shadow="always" class="p-20 w-100"  effect="dark" type="warning">
          {{ this.orderHistory.length }} - өмнө захиалсан байна.
        </el-tag>
      </el-col>
      <!-- bvteegdehvvn -->
      <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4" v-for="product, index in products" :key="index" >
        <el-tag shadow="always" class="p-20 w-100" type="info">
          {{ totalHistoryProductCountCalc(product.product_name) }} - {{ product.product_name }}.
        </el-tag>
      </el-col>
    </el-row>
    <!-- Umnuh  hayg -->
    <el-row type="flex" class="row-bg m-t-20" :gutter="20">
      <h4>Өмнөх хаягийн жагсаалт</h4> <br/>
      <template v-if="addressHistoryList.length">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12"  v-for="orderHistoryItem, index in addressHistoryList" :key="index">
          <div v-if="orderHistoryItem.address">
            <el-button v-if="orderHistoryItem.address.map_link == null" type="info" shadow="always" class="m-t-2"  size="small" @click="fillAddress(orderHistoryItem)">
              {{ orderHistoryItem.address.city}} - {{ orderHistoryItem.address.district}} - {{ orderHistoryItem.address.horoo}} - {{ orderHistoryItem.address.bairname}} - {{ orderHistoryItem.address.bairnote}}
            </el-button>
            <el-button shadow="always" class="m-t-2" v-if="orderHistoryItem.address.map_link != null" type="success"  size="small" @click="fillAddress(orderHistoryItem)">
              {{ orderHistoryItem.address.city}} - {{ orderHistoryItem.address.district}} - {{ orderHistoryItem.address.horoo}} - {{ orderHistoryItem.address.bairname}} - {{ orderHistoryItem.address.bairnote}}
            </el-button>
            </div><br/>
        </el-col>
      </template>
    </el-row>
    <el-row :gutter="20" style="background-color: #eee; border-radius: 20px; padding: 5px;" class="m-t-10 m-b-10">
      <el-col :span="10" class="order_detail_form m-t-40">
        <h3>Захиалгын дэлгэрэнгүй</h3>
        <el-form-item prop="order_channel" class="m-t-20">
          <el-radio-group v-model="orderForm.order.order_channel" class="w-100">
            <el-radio-button label="cc">Дуудлагийн төв</el-radio-button>
            <el-radio-button label="chat">Чат</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form :model="orderForm" :rules="rules" ref="orderForm" label-position="top" class="product_create_form">
          <el-form-item prop="order.customer_phone"
            :rules="[ { required: true, message: 'Утасны дугаар хоосон байна', trigger: 'blur' } ]">
            <el-input v-model="orderForm.order.customer_phone" clearable type="number"
              placeholder="Үйлчлүүлэгчийн утасны дугаар" @change="changeInput()">
            </el-input>
          </el-form-item>
          <el-form-item prop="order.address_city"
            :rules="[ { required: true, message: 'Хот сонгоно уу', trigger: 'blur' } ]">
            <el-select v-model="orderForm.order.address_city" clearable placeholder="Хот аймаг" class="w-100">
              <el-option v-for="item in cities" :key="item.value" :label="item.label" :value="item.value">
                <span style="float: left">{{ item.label }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="orderForm.order.address_city == 'Улаанбаатар'" prop="order.address_district"
            :rules="[ { required: true, message: 'Дүүрэг сонгоно уу', trigger: 'blur' } ]">
            <el-select v-model="orderForm.order.address_district" clearable placeholder="Дүүрэг" class="w-100">
              <el-option v-for="item in districts" :key="item.value" :label="item.label" :value="item.value">
                <span style="float: left">{{ item.label }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="orderForm.order.address_city == 'Улаанбаатар'" prop="order.address_khoroo"
            :rules="[ { required: true, message: 'Хороо сонгоно уу', trigger: 'blur' } ]">
            <el-select v-model="orderForm.order.address_khoroo" clearable placeholder="Хороо" class="w-100">
              <el-option v-for="item in khoroos" :key="item.value" :label="item.label" :value="item.value">
                <span style="float: left">{{ item.label }}</span>
              </el-option>
            </el-select>

          </el-form-item>
          <el-form-item prop="order.address_bair"
            :rules="[ { required: true, message: 'Байрны мэдээлэл хоосон байна', trigger: 'blur' } ]">
            <el-input v-model="orderForm.order.address_bair" placeholder="Байрны тоот болон бусад зүйлс">
            </el-input>
          </el-form-item>
          <el-form-item prop="order.address_description"
            :rules="[ { required: true, message: 'Хаягийн дэлгэрэнгүй хоосон байна', trigger: 'blur' } ]">
            <el-input v-model="orderForm.order.address_description" type="textarea"
              placeholder="Хаягийн дэлгэрэнгүй тайлбар жишээ нь: Орцны код, тойрж ордог гэх мэт">
            </el-input>
          </el-form-item>
          <el-form-item prop="order_description">
            <el-input v-model="orderForm.order.order_description" type="textarea" placeholder="Захиалгын дэлгэрэнгүй">
            </el-input>
          </el-form-item>
          <el-form-item prop="order_type">
            <el-radio-group v-model="orderForm.order.pay_type" class="w-100">
              <el-radio-button label="1">Бэлэн</el-radio-button>
              <el-radio-button label="2">Карт</el-radio-button>
              <el-radio-button label="3">Хосолсон</el-radio-button>
              <el-radio-button label="4">Харилцагч</el-radio-button>
              <el-radio-button label="5">Шилжүүлэг</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="vat_type">
            <el-radio-group v-model="orderForm.order.vat_type" class="w-100">
              <el-radio-button label="1">Хувь хүн</el-radio-button>
              <el-radio-button label="2">Байгууллага</el-radio-button>
              <el-radio-button label="3">Хувь хүн РД</el-radio-button>
              <el-radio-button label="0">Баримт авахгүй</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="vat_regno" v-if="orderForm.order.vat_type == '2' || orderForm.order.vat_type == '3'">
            <el-input v-model="orderForm.order.vat_regno" placeholder="Байгууллагын РД" clearable></el-input>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="10" class="m-t-40" :offset="2">
        <h3>Сагсласан бүтээгдэхүүний жагсаалт - <b> {{totalAmountCalc()}} ₮</b></h3>
        <div v-for="o, index in orderForm.products" :key="index">
          <el-card class="basket_items m-t-10">
            <span>{{ o.product_name }}</span>
            <span class="m-l-40">{{ o.item_count }}</span>
            <span class="m-l-20">{{ parseInt(o.item_count)*parseInt(o.item_price) }} ₮</span>
            <el-button class="m-l-40 flr" type="danger" circle @click="removeFromCart(o)"> x </el-button>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <el-button class="addPbutton flr" type="success" @click="submit('orderForm')">Захиалга үүсгэх /Захиалгаа заавал хянаарай/
    </el-button>
    <h3 class="m-t-20">Бүтээгдэхүүн захиалга</h3>
    <ul class="infinite-list" style="overflow: auto">
      <li v-for="i,index in products" :key="index" class="infinite-list-item">
        <el-row class="row-item">
          <el-col :span="6" :offset="2">
            <div class="product_title">
              <span>{{ index + 1 }}</span>.
              <span>{{ i.product_name }}</span>
            </div>
          </el-col>
          <el-col :span="2">
            <b>{{ parseInt(i.price) }} ₮</b>
          </el-col>
          <el-col :span="2">
            <el-input-number class="m-l-10" size="small" v-model="i.item_count" :min="1" :max="parseInt(i.balance)" />
          </el-col>
          <el-col :span="4" :offset="2">
            <el-button @click="add_product_to_basket(i)" size="small" type="primary">Нэмэх</el-button>
          </el-col>
        </el-row>
      </li>
    </ul>
  </div>
  <loader-item></loader-item>
</template>
<script>
import {
  mapGetters
} from 'vuex'
import services from '@/helpers/services.js'
import LoaderItem from '@/components/loader/loader-item.vue'
export default {
  components: {
    LoaderItem
  },
  computed: mapGetters({
    categoryCreateDrawerStatusGetter: 'categoryCreateDrawerStatusGetter',
    getGlobalImgUrl: 'getGlobalImgUrl',
    lastOrderGetter: 'lastOrderGetter',
    companyInfoGetter: 'companyInfoGetter'
  }),
  data () {
    return {
      item_count: 0,
      branch_id: null,
      num: 1,
      count: 100,
      products: [],
      cities: [{
        value: 'Улаанбаатар',
        label: 'Улаанбаатар'
      }, {
        value: 'Эрдэнэт',
        label: 'Эрдэнэт'
      }, {
        value: 'Дорнод',
        label: 'Дорнод'
      },
      {
        value: 'Хөвсгөл',
        label: 'Хөвсгөл'
      },
      {
        value: 'Орон нутаг',
        label: 'Орон нутаг'
      }
      ],
      districts: [{
        value: 'БЗД',
        label: 'БЗД'
      },
      {
        value: 'ХУД',
        label: 'ХУД'
      },
      {
        value: 'БГД',
        label: 'БГД'
      },
      {
        value: 'СБД',
        label: 'СБД'
      },
      {
        value: 'СХД',
        label: 'СХД'
      },
      {
        value: 'ЧД',
        label: 'ЧД'
      },
      {
        value: 'НАЛАЙХ',
        label: 'НАЛАЙХ'
      },
      {
        value: 'Багахангай',
        label: 'Багахангай'
      },
      {
        value: 'Багануур',
        label: 'Багануур'
      }
      ],
      khoroos: [{
        value: '1 хороо',
        label: '1 хороо'
      },
      {
        value: '2 хороо',
        label: '2 хороо'
      },
      {
        value: '3 хороо',
        label: '3 хороо'
      }, {
        value: '4 хороо',
        label: '4 хороо'
      }, {
        value: '5 хороо',
        label: '5 хороо'
      }, {
        value: '6 хороо',
        label: '6 хороо'
      }, {
        value: '7 хороо',
        label: '7 хороо'
      }, {
        value: '8 хороо',
        label: '8 хороо'
      }, {
        value: '9 хороо',
        label: '9 хороо'
      }, {
        value: '10 хороо',
        label: '10 хороо'
      }, {
        value: '11 хороо',
        label: '11 хороо'
      }, {
        value: '12 хороо',
        label: '12 хороо'
      }, {
        value: '13 хороо',
        label: '13 хороо'
      }, {
        value: '14 хороо',
        label: '14 хороо'
      }, {
        value: '15 хороо',
        label: '15 хороо'
      }, {
        value: '16 хороо',
        label: '16 хороо'
      }, {
        value: '17 хороо',
        label: '17 хороо'
      }, {
        value: '18 хороо',
        label: '18 хороо'
      }, {
        value: '19 хороо',
        label: '19 хороо'
      }, {
        value: '20 хороо',
        label: '20 хороо'
      }, {
        value: '21 хороо',
        label: '21 хороо'
      }, {
        value: '22 хороо',
        label: '22 хороо'
      }, {
        value: '23 хороо',
        label: '23 хороо'
      }, {
        value: '24 хороо',
        label: '24 хороо'
      }, {
        value: '25 хороо',
        label: '25 хороо'
      }, {
        value: '26 хороо',
        label: '26 хороо'
      }, {
        value: '27 хороо',
        label: '27 хороо'
      }, {
        value: '28 хороо',
        label: '28 хороо'
      }, {
        value: '29 хороо',
        label: '29 хороо'
      }, {
        value: '30 хороо',
        label: '30 хороо'
      }, {
        value: '31 хороо',
        label: '31 хороо'
      }, {
        value: '32 хороо',
        label: '32 хороо'
      }, {
        value: '33 хороо',
        label: '33 хороо'
      }, {
        value: '34 хороо',
        label: '34 хороо'
      }, {
        value: '35 хороо',
        label: '35 хороо'
      }, {
        value: '36 хороо',
        label: '36 хороо'
      }, {
        value: '37 хороо',
        label: '37 хороо'
      }, {
        value: '38 хороо',
        label: '38 хороо'
      }, {
        value: '39 хороо',
        label: '39 хороо'
      }, {
        value: '40 хороо',
        label: '40 хороо'
      }, {
        value: '41 хороо',
        label: '41 хороо'
      }, {
        value: '42 хороо',
        label: '42 хороо'
      }, {
        value: '43 хороо',
        label: '43 хороо'
      }, {
        value: '44 хороо',
        label: '44 хороо'
      }, {
        value: '45 хороо',
        label: '45 хороо'
      }, {
        value: '46 хороо',
        label: '46 хороо'
      }, {
        value: '47 хороо',
        label: '47 хороо'
      }
      ],
      addressHistoryList: [],
      orderHistory: [
        {
          address: {
            city: '',
            disctrict: ''
          },
          detail: []
        }
      ],
      orderHistoryProducts: [],
      orderForm: {
        order: {
          branch_name: 'Веб',
          company_name: 'AoR zone LLC',
          address_city: 'Улаанбаатар',
          address_id: null,
          address_district: null,
          address_khoroo: null,
          address_bair: null,
          address_description: '',
          order_channel: 'cc',
          order_type: '1',
          customer_phone: null,
          pay_type: '5',
          vat_type: 0,
          vat_regno: null,
          vat_cust_name: '',
          order_description: '',
          order_status: 40,
          worker_key: null,
          pos_number: '',
          total_amount: 0,
          company_id: 0
        },
        products: [],
        payment: {
          cash: 0,
          credit_card: 0,
          coupon: 0,
          loyalty_point: 0,
          account: 0
        }
      },
      // order_form: {
      //   branch_id: null,

      //   order_type: '1',
      //   pay_type: '1',
      //   vat_type: null,
      //   vat_regno: null,
      //   items: []
      // },
      rules: {
        address_city: [{
          required: true,
          message: 'Хот сонгоогүй байна',
          trigger: 'blur'
        }],
        address_district: [{
          required: true,
          message: 'Дүүрэг сонгоогүй байна',
          trigger: 'blur'
        }],
        address_khoroo: [{
          required: true,
          message: 'Хороо сонгоогүй байна',
          trigger: 'blur'
        }],
        address_description: [{
          required: true,
          message: 'Хаягийн дэлгэрэнгүй',
          trigger: 'blur'
        }],
        customer_phone: [{
          required: true,
          message: 'Захиалгын утасны дугаар',
          trigger: 'blur'
        }]
      }
    }
  },
  created () {
    this.getBranchByProducts()
  },
  methods: {
    totalHistoryProductCountCalc (payload) {
      let count = 0
      this.orderHistory.forEach(element => {
        element.detail.forEach(e => {
          if (e.product_name === payload) {
            count = count + e.item_count
          }
        })
      })
      return count
    },
    fillAddress (payload) {
      console.log(payload)
      this.orderForm.order.address_city = payload.address.city
      this.orderForm.order.address_district = payload.address.district
      this.orderForm.order.address_khoroo = payload.address.horoo
      this.orderForm.order.address_district = payload.address.district
      this.orderForm.order.address_bair = payload.address.bairname
      this.orderForm.order.address_description = payload.address.bairnote
      this.orderForm.order.order_description = payload.order_description
      this.orderForm.order.address_id = payload.address.id
      this.orderForm.order.company_id = this.companyInfoGetter.id
    },
    changeInput () {
      this.addressHistoryList = []
      const payload = {
        phone: this.orderForm.order.customer_phone,
        company_id: this.companyInfoGetter.id
      }
      services.findPhoneAddress(payload)
        .then((res) => {
          res.result.forEach(element => {
            if (element.address) {
              const found = this.addressHistoryList.find(item => item.address.id === element.address.id)
              if (found) {
                console.log('bnaa')
              } else {
                this.addressHistoryList.push(element)
              }
            }

            // this.addressHistoryList.push(element)
          })

          this.orderHistory = res.result
        }).catch((err) => {
          console.log(err)
        })
    },
    totalAmountCalc () {
      let sum = 0
      let total = 0
      this.orderForm.products.forEach(element => {
        console.log(element, 'end')
        sum = parseInt(element.item_price) * element.item_count
        total = sum + total
      })
      return total
    },
    removeFromCart (item) {
      const index = this.orderForm.products.indexOf(item)
      this.orderForm.products.splice(index, 1)
    },
    getBranchByProducts () {
      const payload = {
        company_id: this.companyInfoGetter.id
        // branch_id: 53
      }
      services.productList(payload)
        .then((res) => {
          res.forEach(element => {
            element.item_count = 1
          })
          this.products = res
        }).catch((err) => {
          console.log(err)
        })
    },
    add_product_to_basket (item) {
      const basketItem = {
        id: item.branch_product_id,
        product_name: item.product_name,
        item_price: item.price,
        item_count: item.item_count,
        product_id: item.product_id
      }
      const found = this.orderForm.products.find(item => item.id === basketItem.id)
      if (found) {
        found.item_count = found.item_count + item.item_count
      } else {
        this.orderForm.products.push(basketItem)
      }
    },
    submit (formName) {
      if (this.orderForm.products.length > 0) {
        this.$refs[formName].validate((valid) => {
          this.$store.commit('fullLoading', true)
          if (valid) {
            this.orderForm.order.total_amount = this.totalAmountCalc()
            this.orderForm.order.company_name = this.companyInfoGetter.name
            this.orderForm.order.company_id = this.companyInfoGetter.id
            const payload = {
              order: this.orderForm.order,
              products: this.orderForm.products,
              payment: this.orderForm.payment,
              address: this.orderForm.address
            }
            // this.order.products = this.basketItemsGetter
            services.orderSave(payload)
              .then((response) => {
                this.$store.dispatch('addLastOrder', response)
                this.$store.dispatch('clearBasket')
                this.$store.commit('fullLoading', false)
                if (response) {
                  this.$alert('Таны захиалга амжилттай бүртгэгдлээ', 'Амжилттай', {
                    confirmButtonText: 'Ойлголоо',
                    callback: action => {
                      const payload = {
                        to: this.lastOrderGetter.customer_phone,
                        text: 'Таны ' + this.lastOrderGetter.id +
                            'дугаартай захиалга амжилттай баталгаажлаа. Хаан банкны 5305979107 / Б. Мөнхнасан/ тоот дансанд ' +
                            this.lastOrderGetter.total_amount + 'төгрөгийг ' + this.lastOrderGetter.id +
                            'гүйлгээний утгатай гүйлгээ хийгдсэний дараа захиалга баталгаажна.Drysheep'
                      }
                      services.successOrderSendInfo(payload)
                        .then((response) => {
                          console.log(response, action)
                        })
                        .catch((err) => {
                          console.log(err)
                        })
                      this.$router.push({
                        path: '/orders'
                      })
                    }
                  })
                }
                // this.productList = response
                // this.$store.dispatch('addProducts', response)
                console.log(response)
                this.loading = false
              })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        this.$notify.error({
          title: 'Уучлаарй',
          message: 'Сагс хоосон байна. Заавал бүтээгдэхүүн сонгоно уу'
        })
      }
    }
  }
}

</script>
<style scoped>
  .row-item {
    width: 100%
  }

  .infinite-list {
    height: 300px;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .infinite-list .infinite-list-item {
    border-radius: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    background: #b4d1ee;
    margin: 10px;
    color: #409eff;
  }

  .infinite-list .infinite-list-item+.list-item {
    /* margin-top: 10px; */
  }

  .product_title {
    color: black;
  }

</style>
