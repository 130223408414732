import {
  mainRequests
} from './custom'
export default {
  login (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post('/login', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getRole () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getRole')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getUserById (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getUserById', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  orderList (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/orderList', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  productList (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/products', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  orderSave (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post('/orderSave', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  successOrderSendInfo (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post('/successOrderSendInfo', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  orderUpdateStatus (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/orderUpdateStatus', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  orderIsPaid (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/orderIsPaid', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  orderUpdateStatusCancel  (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/orderUpdateStatusCancel', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  connectOrderDrive  (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/connectOrderDrive', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getEmployeeList  (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getEmployeeList', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  sendSms (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/sendSms', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  orderStatistic (paload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/orderDataCount')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getUserOrderCount (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getUserOrderCount', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  orderDataNewUserCount () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/orderDataNewUserCount')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addressLinkSave (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/addressLinkSave', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  findPhoneAddress (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/findPhoneAddress', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getRegisteredUser (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getRegisteredUser', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getChampionUsers (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getChampionUsers', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
